@charset "utf-8";
/*---------------------------------------------------------------------------------
初期設定
--------------------------------------------------------------------------------- */

/*safariで文字が大きく表示されるのを防ぐ*/

html {
	-webkit-text-size-adjust: 100%;
}

body {
	font-family: 'Noto Sans JP', "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "游ゴシック", YuGothic, "メイリオ", Meiryo, sans-serif;
	font-size: 15px;
	color: #4d4d4d;
	line-height: 1.6;
	margin: 0px;
	padding: 0px;
	overflow-x: hidden;
}

h1, h2, h3, h4, h5, h6 {
	line-height: 1.4;
	letter-spacing: 0.1em;
}

h1, h2, h3, h4, h5, h6, ul, ol, dl, p, li, dt, dd, th, td {
	margin: 0px;
	padding: 0px;
	border: 0;
	font-size: 100%;
	vertical-align: baseline;
	font-weight: normal;
}

ol, ul, li {
	list-style: none;
}

table {
	border-collapse: collapse;
	border-spacing: 0;
}

th, td {
	text-align: left;
	vertical-align: top;
}

/* HTML5 display-role reset for older browsers */

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section, main {
	display: block;
	margin: 0px;
	padding: 0px;
}

img {
	vertical-align: top;
	font-size: 0;
	line-height: 0;
}

img:not([width]) {
	width: 100%;
	height: auto;
}

a img {
	border-top-style: none;
	border-right-style: none;
	border-bottom-style: none;
	border-left-style: none;
}

body, html {
	height: 100%;
}

a:link, a:visited, a:hover, a:active {
	text-decoration: none;
}

ul {
	list-style-type: none;
}

button {
	background-color: transparent;
	border: none;
	cursor: pointer;
	outline: none;
	padding: 0;
	appearance: none;
}

.clear {
	clear: both;
	display: none;
}

.clearfix:after {
	content: "";
	display: block;
	clear: both;
	height: 0;
}

.pc-only {
	display: block;
}

.sp-only {
	display: none;
}

.box {
	width: 1080px;
	margin: 0 auto;
	position: relative;
}

@media screen and (max-width: 768px) {
	body {
		font-size: 14px;
	}
	.box {
		width: 100%;
		margin: 0 auto;
		position: relative;
	}
	.pc-only {
		display: none;
	}
	.sp-only {
		display: block;
	}
}

body {
	background: url("../images/bg.jpg") center repeat-y;
	background-size: cover;
}

/*----------------------------------------------
mv
----------------------------------------------*/

#mv .inner_01 {
	background: url("../images/mv.png") center no-repeat;
	width: 1280px;
	height: 1010px;
	margin-left: 50%;
	transform: translateX(-50%);
	text-indent: 1000%;
	white-space: nowrap;
	overflow: hidden;
}

/*----------------------------------------------
sec_01
----------------------------------------------*/

#sec_01 {
	margin: -320px 0 80px 0;
	position: relative;
	z-index: 1;
}

#sec_01 .inner_01 {
	background: url("../images/sec_01.png") center no-repeat;
	width: 1127px;
	height: 1181px;
	margin-left: 50%;
	transform: translateX(-50%);
	text-indent: 1000%;
	white-space: nowrap;
	overflow: hidden;
}

#sec_01 .gif_01 {
	position: absolute;
	display: block;
	width: 187px;
	right: 0;
	top: 20px;
}

/*----------------------------------------------
sec_02
----------------------------------------------*/

#sec_02 {
	position: relative;
}

#sec_02:before {
	content: "";
	position: absolute;
	top: -220px;
	left: 0;
	width: 619px;
	height: 341px;
	background: url("../images/bg01.png") 0 0 no-repeat;
	background-size: 100% auto;
	z-index: 0;
}

#sec_02 .inner_01 {
	background: url("../images/sec_02.png") center no-repeat;
	width: 1108px;
	height: 716px;
	margin-left: 50%;
	transform: translateX(-50%);
	text-indent: 1000%;
	white-space: nowrap;
	overflow: hidden;
}

#sec_02 .f_btn {
	position: absolute;
	display: block;
	width: 321px;
	height: 53px;
	right: 45px;
	top: 240px;
}

#sec_02 .f_btn:hover {
	opacity: 0.6;
}

#sec_02 .gif_01 {
	position: absolute;
	display: block;
	width: 196px;
	right: 40px;
	top: -30px;
}

/*----------------------------------------------
sec_03
----------------------------------------------*/

#sec_03 {
	position: relative;
}

#sec_03:before {
	content: "";
	position: absolute;
	top: 50%;
	right: 0;
	width: 588px;
	height: 341px;
	background: url("../images/bg02.png") 0 0 no-repeat;
	background-size: 100% auto;
	z-index: 0;
}

#sec_03 .box {
	background: url("../images/sec03_bg01.png") center no-repeat;
	background-size: cover;
	box-sizing: border-box;
	padding-bottom: 30px;
}

#sec_03 .txt_01 {
	background: url("../images/sec03_txt01.png") center no-repeat;
	width: 1080px;
	height: 129px;
	margin-left: 50%;
	transform: translateX(-50%);
	text-indent: 1000%;
	white-space: nowrap;
	overflow: hidden;
}

#sec_03 .txt_02 {
	background: url("../images/sec03_txt02.png") center no-repeat;
	width: 1080px;
	height: 82px;
	margin: 20px auto;
	margin-left: 50%;
	transform: translateX(-50%);
	text-indent: 1000%;
	white-space: nowrap;
	overflow: hidden;
}

#sec_03 .tab_area {
	display: flex;
	justify-content: space-between;
	width: 730px;
	margin: auto;
}

input[type=radio] {
	display: none;
	/* ラジオボタンを非表示にする */
}

#tab1, #tab2 {
	cursor: pointer;
}

/*.tab_area label:hover{
	opacity: 0.8;
}*/

.tab1_label {
	background: url("../images/btn_01_off.png") 0 0 no-repeat;
	width: 337px;
	height: 104px;
	text-indent: 1000%;
	white-space: nowrap;
	overflow: hidden;
}

.tab2_label {
	background: url("../images/btn_02_off.png") 0 0 no-repeat;
	width: 337px;
	height: 104px;
	text-indent: 1000%;
	white-space: nowrap;
	overflow: hidden;
}

#tab1:checked~.tab_area .tab1_label {
	background: url("../images/btn_01.png") 0 0 no-repeat;
	width: 337px;
	height: 104px;
}

#tab2:checked~.tab_area .tab2_label {
	background: url("../images/btn_02.png") 0 0 no-repeat;
	width: 337px;
	height: 104px;
}

.tab_panel {
	display: none;
}

#tab1:checked~.panel_area #panel1 {
	display: block;
}

#panel1 .box_01 {
	border: #f04843 solid 3px;
}

#tab2:checked~.panel_area #panel2 {
	display: block;
}

#panel2 .box_01 {
	border: #e9d92a solid 3px;
}

/*----------検索エリア-------------*/

#sec_03 .search_box {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 730px;
	margin: 20px auto 50px;
}

#sec_03 .search_box input {
	height: 45px;
	width: 420px;
	font-size: 22px;
	padding-left: 10px;
}

*, *:after, *:before {
	box-sizing: border-box;
}

.select {
	position: relative;
	display: block;
	width: 150px;
	color: #051c66;
	vertical-align: middle;
	text-align: left;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	-webkit-touch-callout: none;
	font-size: 22px;
	font-weight: bold;
}

.select .placeholder {
	position: relative;
	display: block;
	background-color: #ffffff;
	z-index: 1;
	cursor: pointer;
	padding: 0 0 0 10px;
	line-height: 45px;
	border: 2px solid #0c53c2;
}

.select .placeholder:before {
	content: "";
	position: absolute;
	display: block;
	right: 35px;
	top: 50%;
	-webkit-transform: translateY(-50%);
	transform: translateY(-50%);
	width: 2px;
	height: 26px;
	background: #999999;
}

.select .placeholder:after {
	content: "";
	position: absolute;
	display: block;
	right: 5px;
	top: 50%;
	-webkit-transform: translateY(-50%);
	transform: translateY(-50%);
	width: 0px;
	height: 0px;
	margin: auto;
	border-style: solid;
	border-color: #051c66 transparent transparent transparent;
	border-width: 15px 10px 0 10px;
}

.select.is-open .placeholder:after {
	content: "";
	position: absolute;
	display: block;
	right: 5px;
	top: 50%;
	-webkit-transform: translateY(-50%);
	transform: translateY(-50%);
	width: 0px;
	height: 0px;
	margin: auto;
	border-style: solid;
	border-color: #051c66 transparent;
	border-width: 0 10px 15px 10px;
}

.select.is-open ul {
	display: block;
}

.select ul {
	display: none;
	position: absolute;
	width: 100%;
	background: #fff;
	top: 100%;
	left: 0;
	list-style: none;
	padding: 0;
	z-index: 100;
	border: 2px solid #0c53c2;
}

.select ul li {
	display: block;
	text-align: left;
	padding: 0 0 0 10px;
	color: #051c66;
	font-weight: bold;
	cursor: pointer;
}

.select ul li:hover {
	background: #bae9ff;
}

#sec_03 button {
	border: 2px solid #0c53c2;
	border-radius: 8px;
}

/*----------キャラリスト------------*/

#sec_03 .box_01 {
	width: 970px;
	background: rgba(255, 255, 255, 0.4);
	padding: 30px;
	height: 600px;
	overflow-y: scroll;
	margin: 0 auto 50px;
}

#sec_03 .box_01 .txt_03 {
	margin-bottom: 30px;
}

input[name="red"]:checked+label {
	border: #f04843 solid 3px!important;
}

input[name="yellow"]:checked+label {
	border: #e9d92a solid 3px!important;
}

#sec_03 .box_01 .chara_list {
	display: flex;
	justify-content: normal;
	flex-wrap: wrap;
}

#sec_03 .label {
	display: block;
	width: 168px;
	box-shadow: 3px 3px 6px 0px rgba(0, 0, 0, 0.2);
	margin-left: 5px;
	margin-bottom: 20px;
	border: 3px solid #FFFFFF;
	box-sizing: border-box;
}

#sec_03 .box_02 {
	width: 970px;
	background: url("../images/sec03_bg02.png") center no-repeat;
	background-size: cover;
	padding: 30px 0;
	margin: auto;
}

#sec_03 .box_02 .txt_04 {
	width: 314px;
	margin: auto;
}

#sec_03 .box_02 .txt_05 {
	display: flex;
	align-items: center;
	margin: 10px 0 30px 220px;
}

#sec_03 .box_02 .txt_05 input {
	width: 530px;
	height: 50px;
	border: 3px solid #2064ff;
	margin-right: 10px;
	font-size: 24px;
	font-weight: bold;
	color: #0036ff;
	font-family: "Meiryo", "メイリオ", sans-serif;
	padding-left: 10px;
}

#sec_03 .box_02 .txt_05 img {
	width: 81px;
}

#sec_03 .box_02 .card_box {
	width: 810px;
	margin: auto;
	box-shadow: 0 3px 10px 3px rgba(0, 0, 0, 0.7);
	border: 3px solid #FFFFFF;
}

#sec_03 .btn_01 {
	display: block;
	background: url("../images/btn_03.png") center no-repeat;
	background-size: cover;
	width: 626px;
	height: 198px;
	text-indent: 100%;
	white-space: nowrap;
	overflow: hidden;
	margin: auto;
}

#sec_03 .btn_01:hover {
	background: url("../images/btn_03_hover.png") center no-repeat;
}

#sec_03 .btn_bg {
	width: 1080px;
	background: url("../images/btn_bg.png") center no-repeat;
}

#sec_03 .sub {
	font-size: 18px;
	color: #053e93;
	text-align: center;
}

/*----------------------------------------------
sec_04
----------------------------------------------*/

#sec_04 {
	padding: 80px 0;
	background: url("../images/bg03.png") center bottom repeat-x;
	background-size: 100% auto;
}

#sec_04 .btn_02 {
	display: block;
	background: url("../images/btn_04.png") center no-repeat;
	background-size: cover;
	width: 527px;
	height: 94px;
	text-indent: 100%;
	white-space: nowrap;
	overflow: hidden;
	margin: auto;
}

#sec_04 .btn_02:hover {
	background: url("../images/btn_04_hover.png") center no-repeat;
}

/*----------------------------------------------
footer
----------------------------------------------*/

footer {
	background: #3165b3;
}

footer dl {
	width: 800px;
	margin: auto;
	font-size: 12px;
	color: #FFFFFF;
	padding: 40px 0;
}

footer dt {
	padding-left: 20px;
	position: relative;
	margin-top: 10px;
}

footer dt:before {
	position: absolute;
	display: block;
	content: "";
	background: #fff;
	width: 13px;
	height: 13px;
	top: 5px;
	left: 0;
}

footer li {
	padding-left: 20px;
	position: relative;
}

footer .gif_01 {
	position: absolute;
	display: block;
	width: 163px;
	left: -40px;
	top: -60px;
}

footer .gif_02 {
	position: absolute;
	display: block;
	width: 212px;
	right: -40px;
	top: -60px;
}

footer .copy {
	background: #0e356f;
	color: #FFFFFF;
	text-align: center;
	font-size: 12px;
	line-height: 28px;
}

footer a:link, a:visited, a:hover, a:active {
	color: #FFFF99;
}

/*--------------------------------------------------------------------------------------------
SP
--------------------------------------------------------------------------------------------*/

@media screen and (max-width: 768px) {
	body {
		background: url("../images/bg.jpg") center repeat-y;
		background-attachment: fixed;
		background-size: 100% auto;
	}
	/*----------------------------------------------
	mv
	----------------------------------------------*/
	#mv .inner_01 {
		background: url("../images/sp_mv.png") center 0 no-repeat;
		background-size: 100% auto;
		width: 100%;
		height: 0;
		padding-top: calc(100%*804/640);
	}
	/*----------------------------------------------
	sec_01
	----------------------------------------------*/
	#sec_01 {
		margin: -34% 0 0 0;
	}
	#sec_01 .inner_01 {
		background: url("../images/sp_sec_01.png") center 0 no-repeat;
		background-size: 100% 100%;
		width: 100%;
		height: 0;
		padding-top: calc(100%*713/640);
	}
	#sec_01 .gif_01 {
		width: 16%;
		right: 5px;
		top: 5px;
	}
	/*----------------------------------------------
	sec_02
	----------------------------------------------*/
	#sec_02:before {
		display: none;
	}
	#sec_02 .inner_01 {
		background: url("../images/sp_sec_02.png") center 0 no-repeat;
		background-size: 100% auto;
		width: 100%;
		height: 0;
		padding-top: calc(100%*396/640);
		margin-top: 20px;
	}
	#sec_02 .f_btn {
		position: absolute;
		display: block;
		width: 35%;
		height: auto;
		right: 10px;
		top: 35%;
	}
	#sec_02 .gif_01 {
		width: 16%;
		right: 20px;
		top: -10px;
	}
	/*----------------------------------------------
	sec_03
	----------------------------------------------*/
	#sec_03:before {
		display: none;
	}
	#sec_03 .box {
		padding-bottom: 20px;
	}
	#sec_03 .txt_01 {
		background: url("../images/sp_sec03_txt01.png") center 0 no-repeat;
		background-size: 100% auto;
		width: 100%;
		height: 0;
		padding-top: calc(100%*78/640);
	}
	#sec_03 .txt_02 {
		background: url("../images/sp_sec03_txt02.png") center 0 no-repeat;
		background-size: 100% auto;
		width: 100%;
		height: 0;
		padding-top: calc(100%*53/640);
		margin: 10px auto 20px;
		margin-left: 50%;
		transform: translateX(-50%);
	}
	#sec_03 .tab_wrap {
		box-sizing: border-box;
		padding: 0 25px;
	}
	#sec_03 .tab_area {
		width: 100%;
	}
	#sec_03 .tab1_label {
		margin-right: 20px;
	}
	.tab1_label {
		background: url("../images/btn_01_off.png") 0 0 no-repeat;
		background-size: 100% auto;
		width: 100%;
		height: 0;
		padding-top: calc(100%*52/337);
	}
	.tab2_label {
		background: url("../images/btn_02_off.png") 0 0 no-repeat;
		background-size: 100% auto;
		width: 100%;
		height: 0;
		padding-top: calc(100%*52/337);
	}
	#tab1:checked~.tab_area .tab1_label {
		background: url("../images/btn_01.png") 0 0 no-repeat;
		background-size: 100% auto;
		width: 100%;
		height: 0;
		padding-top: calc(100%*52/337);
	}
	#tab2:checked~.tab_area .tab2_label {
		background: url("../images/btn_02.png") 0 0 no-repeat;
		background-size: 100% auto;
		width: 100%;
		height: 0;
		padding-top: calc(100%*52/337);
	}
	/*----------検索エリア-------------*/
	#sec_03 .search_box {
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 100%;
		margin: 20px auto 20px;
	}
	#sec_03 .search_box input {
		height: 30px;
		width: 50%;
		font-size: 13px;
		padding-left: 5px;
	}
	.select {
		width: 30%;
		margin-right: 5px;
		font-size: 3vw;
		font-weight: bold;
	}
	.select .placeholder {
		line-height: 30px;
	}
	.select .placeholder:before {
		right: 22px;
		width: 1px;
		height: 22px;
	}
	.select .placeholder:after {
		border-color: #051c66 transparent transparent transparent;
		border-width: 10px 6px 0 6px;
	}
	.select.is-open .placeholder:after {
		border-color: #051c66 transparent;
		border-width: 0 6px 10px 6px;
	}
	.select ul li {
		padding: 3px 10px 3px 10px;
		font-weight: bold;
	}
	#sec_03 button {
		width: 20%;
		margin-left: 5px;
	}
	/*----------キャラリスト------------*/
	#sec_03 .box_01 {
		width: 100%;
		padding: 10px;
		height: 200px;
		margin: 0 auto 20px;
	}
	#sec_03 .box_01 .txt_03 {
		margin-bottom: 15px;
	}
	input[name="red"]:checked+label {
		border: #f04843 solid 2px!important;
	}
	input[name="yellow"]:checked+label {
		border: #e9d92a solid 2px!important;
	}
	#sec_03 .label {
		width: calc(20% - 2.5px);
		margin-left: 2px;
		margin-bottom: 5px;
		border: 1px solid #FFFFFF;
		box-sizing: border-box;
	}
	#sec_03 .box_wrap {
		box-sizing: border-box;
		padding: 0 25px;
	}
	#sec_03 .box_02 {
		width: 100%;
		height: 0;
		background: url("../images/sec03_bg02.png") center 0 no-repeat;
		background-size: cover;
		padding: 0 0;
		padding-top: calc(100%*621/979);
		padding-bottom: 40px;
		position: relative;
	}
	#sec_03 .box_02_inner {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		box-sizing: border-box;
		padding: 15px 20px;
	}
	#sec_03 .box_02 .txt_04 {
		width: 40%;
	}
	#sec_03 .box_02 .txt_05 {
		justify-content: center;
		margin: 5px 0 10px 0;
	}
	#sec_03 .box_02 .txt_05 input {
		width: 70%;
		height: 30px;
		border: 2px solid #2064ff;
		margin-right: 5px;
		font-size: 3vw;
		padding-left: 5px;
	}
	#sec_03 .box_02 .txt_05 img {
		width: 10%;
	}
	#sec_03 .box_02 .card_box {
		width: 100%;
		margin: auto;
	}
	#sec_03 .box_02 .card_box img {
		display: block;
		width: 100%;
		margin: auto;
	}
	#sec_03 .btn_01 {
		background: url("../images/sp_btn_03.png") center 0 no-repeat;
		background-size: 100% auto;
		width: 100%;
		height: 0;
		padding-top: calc(100%*128/597);
	}
	#sec_03 .btn_01:hover {
		background: url("../images/sp_btn_03_hover.png") center 0 no-repeat;
		background-size: 100% auto;
		width: 100%;
		height: 0;
		padding-top: calc(100%*128/597);
	}
	#sec_03 .btn_bg {
		width: 100%;
		background: url("../images/btn_bg.png") center no-repeat;
		background-size: 100% auto;
	}
	#sec_03 .sub {
		font-size: 14px;
	}
	/*----------------------------------------------
	sec_04
	----------------------------------------------*/
	#sec_04 {
		padding: 25px 25px 30px;
		box-sizing: border-box;
	}
	#sec_04 .btn_02 {
		background: url("../images/btn_04.png") center no-repeat;
		background-size: 100% auto;
		width: 100%;
		height: 0;
		padding-top: calc(100%*94/527);
	}
	#sec_04 .btn_02:hover {
		background: url("../images/btn_04_hover.png") center no-repeat;
		background-size: 100% auto;
		width: 100%;
		height: 0;
		padding-top: calc(100%*94/527);
	}
	/*----------------------------------------------
	footer
	----------------------------------------------*/
	footer {
		box-sizing: border-box;
		padding: 0 0;
	}
	footer dl {
		width: 70%;
		padding: 15px 0;
	}
	footer dt {
		padding-left: 15px;
		margin-top: 10px;
	}
	footer dt:before {
		width: 10px;
		height: 10px;
		top: 8px;
	}
	footer .gif_01 {
		width: 16%;
		left: 0;
		top: -15px;
	}
	footer .gif_02 {
		width: 20%;
		right: 0;
		top: -15px;
	}
	footer .copy {
		box-sizing: border-box;
		font-size: 10px;
		line-height: 1.5;
		padding: 5px 5px;
	}
}